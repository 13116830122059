var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isAllowedToViewDialog
        ? _c("AllowedToView", {
            attrs: { report: _vm.reportModel, editor: "voorcontrole" },
            on: { handleAlert: _vm.allowedToView }
          })
        : _vm._e(),
      _vm.isAllowedToView
        ? [
            _c(
              "v-container",
              {
                staticClass:
                  "reportContainer reportContainer--veldwerk container--wide",
                attrs: { "grid-list-lg": "" }
              },
              [
                _c(
                  "v-layout",
                  { attrs: { row: "", wrap: "" } },
                  [
                    _c("v-flex", { attrs: { xs12: "", lg5: "" } }, [
                      _c(
                        "div",
                        { staticClass: "scrollColumn" },
                        [
                          _c(
                            "div",
                            {
                              class: {
                                "reject-border":
                                  _vm.reportModel.reject_reasons.length
                              }
                            },
                            [
                              _vm.reportModel
                                ? _c(
                                    "div",
                                    { staticClass: "elementPanel profile" },
                                    [
                                      _vm.isLoading
                                        ? _c("LoaderCard", {
                                            attrs: {
                                              flat: "",
                                              type: "spinner--center",
                                              minHeight: "300px"
                                            }
                                          })
                                        : _vm._e(),
                                      !_vm.isLoading
                                        ? [
                                            _c(
                                              "v-layout",
                                              {
                                                staticClass: "profileContainer",
                                                attrs: { row: "", wrap: "" }
                                              },
                                              [
                                                _c(
                                                  "v-flex",
                                                  [
                                                    [
                                                      _c(
                                                        "h1",
                                                        {
                                                          staticClass:
                                                            "profile__userName"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.reportModel
                                                                .case_number
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "profile__userRoles"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.reportModel
                                                                  .type
                                                                  ? _vm
                                                                      .reportModel
                                                                      .type.name
                                                                  : ""
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-layout",
                                              { attrs: { row: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm4: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn__fadedText"
                                                      },
                                                      [_vm._v("Naam aanvrager")]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm8: "" }
                                                  },
                                                  [
                                                    _c("p", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.reportModel
                                                            .applicant
                                                            ? _vm.reportModel
                                                                .applicant.name
                                                            : ""
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-layout",
                                              { attrs: { row: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm4: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn__fadedText"
                                                      },
                                                      [_vm._v("Adres")]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm8: "" }
                                                  },
                                                  [
                                                    _vm.reportModel.address
                                                      ? _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: _vm.reportModel.googleMapUrl(),
                                                              target: "_blank"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .reportModel
                                                                    .address
                                                                    .street
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .reportModel
                                                                    .address
                                                                    .number
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .reportModel
                                                                    .address
                                                                    .number_add
                                                                ) +
                                                                ", " +
                                                                _vm._s(
                                                                  _vm
                                                                    .reportModel
                                                                    .address
                                                                    .postcode
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .reportModel
                                                                    .address
                                                                    .city
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-layout",
                                              { attrs: { row: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm4: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn__fadedText"
                                                      },
                                                      [_vm._v("Opgestuurd")]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm8: "" }
                                                  },
                                                  [
                                                    _c("p", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.reportModel
                                                              .submitted_at ===
                                                              "0000-00-00 00:00:00"
                                                              ? "-"
                                                              : _vm.formatDateSeconds(
                                                                  _vm
                                                                    .reportModel
                                                                    .submitted_at
                                                                )
                                                          ) +
                                                          " "
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-layout",
                                              { attrs: { row: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm4: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn__fadedText"
                                                      },
                                                      [_vm._v("Rapport type")]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm8: "" }
                                                  },
                                                  [
                                                    _c("p", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.reportModel.type
                                                            ? _vm.reportModel
                                                                .type.name
                                                            : ""
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-layout",
                                              { attrs: { row: "" } },
                                              [
                                                _vm.reportModel
                                                  .isAannemersReport
                                                  ? _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm4: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Naam Aannemer"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                !_vm.reportModel
                                                  .isAannemersReport
                                                  ? _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm4: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Naam Expert"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm8: "" }
                                                  },
                                                  [
                                                    _vm.reportModel.expert
                                                      ? _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.reportModel
                                                                .expert.name
                                                            )
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _vm.reportModel.isAannemersReport
                                              ? _c(
                                                  "v-layout",
                                                  { attrs: { row: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm4: "" }
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn__fadedText"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Naam Expert"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _vm.reportModel
                                                      .second_expert
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "infoPanelColumn",
                                                            attrs: { sm8: "" }
                                                          },
                                                          [
                                                            _c("p", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .reportModel
                                                                    .second_expert
                                                                    .name
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "v-layout",
                                              { attrs: { row: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm4: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn__fadedText"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "profile.reportTag"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm8: "" }
                                                  },
                                                  _vm._l(
                                                    _vm.reportModel.tags,
                                                    function(tag, index) {
                                                      return _c(
                                                        "p",
                                                        { key: index },
                                                        [
                                                          _vm.reportModel.tags
                                                            .length > 1
                                                            ? _c("span", [
                                                                tag[0]
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            tag.name
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ])
                                                                  : _c("span", [
                                                                      _vm._v(
                                                                        "," +
                                                                          _vm._s(
                                                                            tag.name
                                                                          )
                                                                      )
                                                                    ])
                                                              ])
                                                            : _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      tag.name
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-layout",
                                              { attrs: { row: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm4: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn__fadedText"
                                                      },
                                                      [_vm._v("Bouwjaar")]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm8: "" }
                                                  },
                                                  [
                                                    _c("p", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.generalKeys
                                                            .BouwJaar
                                                            ? _vm.generalKeys
                                                                .BouwJaar.value
                                                            : "-"
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-layout",
                                              { attrs: { row: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm4: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn__fadedText"
                                                      },
                                                      [_vm._v("Eigendom sinds")]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm8: "" }
                                                  },
                                                  [
                                                    _c("p", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.generalKeys
                                                            .EigendomSinds
                                                            ? _vm.generalKeys
                                                                .EigendomSinds
                                                                .value
                                                            : "-"
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-layout",
                                              { attrs: { row: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm4: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn__fadedText"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Verhaal aanvrager"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm8: "" }
                                                  },
                                                  [
                                                    _c("p", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.generalKeys
                                                            .storyOfApplicant
                                                            ? _vm.generalKeys
                                                                .storyOfApplicant
                                                                .value
                                                            : "-"
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-layout",
                                              { attrs: { row: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm4: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn__fadedText"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Opmerking deskundige"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm8: "" }
                                                  },
                                                  [
                                                    _c("p", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.sanitizeString(
                                                            _vm.generalKeys
                                                              .ExpertComment
                                                              ? _vm.generalKeys
                                                                  .ExpertComment
                                                                  .value
                                                              : "-"
                                                          )
                                                        )
                                                      }
                                                    }),
                                                    _c("p", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.sanitizeString(
                                                            _vm.generalKeys
                                                              .OpmerkingDeskundige
                                                              ? _vm.generalKeys
                                                                  .OpmerkingDeskundige
                                                                  .value
                                                              : "-"
                                                          )
                                                        )
                                                      }
                                                    })
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-layout",
                                              { attrs: { row: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm4: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn__fadedText"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Opmerking aanvrager"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm8: "" }
                                                  },
                                                  [
                                                    _c("p", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.sanitizeString(
                                                            _vm.generalKeys
                                                              .relevantRemarksApplicant
                                                              ? _vm.generalKeys
                                                                  .relevantRemarksApplicant
                                                                  .value
                                                              : "-"
                                                          )
                                                        )
                                                      }
                                                    }),
                                                    _c("p", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.sanitizeString(
                                                            _vm.generalKeys
                                                              .OpmerkingAanvrager
                                                              ? _vm.generalKeys
                                                                  .OpmerkingAanvrager
                                                                  .value
                                                              : "-"
                                                          )
                                                        )
                                                      }
                                                    })
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-layout",
                                              { attrs: { row: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm4: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn__fadedText"
                                                      },
                                                      [_vm._v("Geaccepteerd")]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm8: "" }
                                                  },
                                                  [
                                                    _c("p", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.reportModel
                                                              .approved_at ===
                                                              "0000-00-00 00:00:00"
                                                              ? "-"
                                                              : _vm.formatDateSeconds(
                                                                  _vm
                                                                    .reportModel
                                                                    .approved_at
                                                                )
                                                          ) +
                                                          " "
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-layout",
                                              { attrs: { row: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm4: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn__fadedText"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Totaal schadebedrag incl. BTW"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm.reportModel.total_repairs
                                                  ? _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { sm8: "" }
                                                      },
                                                      [
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "currency"
                                                              )(
                                                                _vm.reportModel
                                                                  .total_repairs
                                                                  .total_in
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-layout",
                                              { attrs: { row: "" } },
                                              [
                                                _vm.reportModel.reject_reasons
                                                  .length
                                                  ? _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn",
                                                        attrs: { xs12: "" }
                                                      },
                                                      [
                                                        _c("h4", [
                                                          _vm._v(
                                                            "Afkeurreden(en):"
                                                          )
                                                        ]),
                                                        _c(
                                                          "ul",
                                                          _vm._l(
                                                            _vm.reportModel
                                                              .reject_reasons,
                                                            function(
                                                              rejectReason,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "li",
                                                                { key: index },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        rejectReason.name
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                        _c("h4", [
                                                          _vm._v("Toelichting:")
                                                        ]),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.reportModel
                                                                .reject_comment
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-layout",
                                              { attrs: { row: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm4: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "infoPanelColumn__fadedText"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Interne afwijsuitleg"
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    staticClass:
                                                      "infoPanelColumn",
                                                    attrs: { sm8: "" }
                                                  },
                                                  [
                                                    _c("v-textarea", {
                                                      staticClass: "pt-0 mt-0",
                                                      attrs: {
                                                        placeholder:
                                                          "Uitleg voor het afkeuren",
                                                        name: "explanation",
                                                        disabled:
                                                          _vm.isLockedByUser
                                                      },
                                                      on: {
                                                        input:
                                                          _vm.debounceExplanation
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.reportModel
                                                            .internal_reject_comment,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.reportModel,
                                                            "internal_reject_comment",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "reportModel.internal_reject_comment"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm.reportModel.validations
                                              ? _c(
                                                  "v-layout",
                                                  {
                                                    staticClass: "item__list",
                                                    attrs: { wrap: "" }
                                                  },
                                                  [
                                                    _vm._l(
                                                      _vm.reportModel
                                                        .validations,
                                                      function(
                                                        validation,
                                                        key
                                                      ) {
                                                        return [
                                                          validation !== "ok"
                                                            ? _c(
                                                                "v-flex",
                                                                {
                                                                  key: key,
                                                                  staticClass:
                                                                    "list__item",
                                                                  attrs: {
                                                                    xs12: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "ValidationWarning",
                                                                    {
                                                                      attrs: {
                                                                        validation: validation,
                                                                        validationKey: key
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      }
                                                    )
                                                  ],
                                                  2
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ]
                          ),
                          !_vm.isLoading && _vm.damages
                            ? [
                                _c("DamagePanel", {
                                  ref: "damagePanel",
                                  attrs: {
                                    damages: _vm.damages,
                                    includes: [
                                      "validations",
                                      "reject_reasons",
                                      "media",
                                      "repairs"
                                    ],
                                    visibility: _vm.damageVisibility,
                                    panelsFromStorage: _vm.panelsFromStorage,
                                    disabled: _vm.isLockedByUser
                                  },
                                  on: {
                                    reloadDamages: _vm.initialize,
                                    panelChanged: function($event) {
                                      return _vm.handlePanelChanged($event)
                                    }
                                  }
                                })
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]),
                    _c("v-flex", { attrs: { xs12: "", lg7: "" } }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "elementPanel elementPanel--iframe profile"
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { "mb-1": "" } },
                                [
                                  _c(
                                    "MiButton",
                                    {
                                      staticClass: "pr-2",
                                      attrs: {
                                        color: "primary",
                                        compact: "true",
                                        icon:
                                          _vm.currentPdfType === "quick"
                                            ? "loop"
                                            : "picture_as_pdf",
                                        outline: "true",
                                        fontSize: "10",
                                        round: "true"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.setCurrentPdf("quick")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.currentPdfType === "quick"
                                              ? "herlaad"
                                              : ""
                                          ) +
                                          " quick pdf "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        color: "primary",
                                        compact: "true",
                                        icon:
                                          _vm.currentPdfType === "preview"
                                            ? "loop"
                                            : "picture_as_pdf",
                                        outline: "true",
                                        fontSize: "10",
                                        round: "true"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.setCurrentPdf("preview")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.currentPdfType === "preview"
                                              ? "herlaad"
                                              : ""
                                          ) +
                                          " volledige pdf "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { staticClass: "text-xs-right" },
                                [
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        color: "secondary",
                                        compact: "true",
                                        icon: "exit_to_app",
                                        iconRight: "true",
                                        fontSize: "10"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.navigateToUrl(
                                            "/expert/" + _vm.reportModel.uuid
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" Deskundige editor ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "object",
                            {
                              staticStyle: {
                                width: "100%",
                                height: "96%",
                                "min-height": "600px"
                              },
                              attrs: { data: _vm.fileUrl ? _vm.fileUrl : null }
                            },
                            [
                              _c("embed", {
                                staticStyle: {
                                  width: "100%",
                                  height: "96%",
                                  "min-height": "600px"
                                },
                                attrs: { src: _vm.fileUrl ? _vm.fileUrl : null }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c("ApprovalBar", {
              attrs: { backUrl: "/reports/" + _vm.reportModel.uuid },
              scopedSlots: _vm._u(
                [
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "actionContainer" },
                          [
                            _c(
                              "MiButton",
                              {
                                attrs: {
                                  disabled: _vm.isLockedByUser,
                                  color: "success",
                                  small: "true",
                                  icon: "assignment_ind"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.claimDossier()
                                  }
                                }
                              },
                              [_vm._v(" Claim voorcontrole ")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "actionContainer" },
                          [
                            _c(
                              "MiButton",
                              {
                                attrs: {
                                  disabled: _vm.isLockedByUser,
                                  color: "warning",
                                  small: "true",
                                  icon: "cancel"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.toggleApproveDialog(
                                      "rejected",
                                      "Verander naar:",
                                      "rejected"
                                    )
                                  }
                                }
                              },
                              [_vm._v(" Terugzetten ")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "actionContainer" },
                          [
                            _c(
                              "MiButton",
                              {
                                attrs: {
                                  disabled: _vm.isLockedByUser,
                                  color: "yellow",
                                  small: "true",
                                  icon: "cancel"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.toggleApproveDialog(
                                      "supervisor",
                                      "terugzetten naar:",
                                      "rejected"
                                    )
                                  }
                                }
                              },
                              [_vm._v(" Naar Supervisor ")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "actionContainer" },
                          [
                            _c(
                              "MiButton",
                              {
                                attrs: {
                                  disabled: _vm.isLockedByUser,
                                  color: "blue-magenta",
                                  small: "true",
                                  icon: "check_circle"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.toggleApproveDialog(
                                      "checking",
                                      "Verander naar:",
                                      "approved"
                                    )
                                  }
                                }
                              },
                              [_vm._v(" Te controleren ")]
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3856013738
              )
            }),
            _vm.approvalDialogOpen
              ? _c(
                  "v-dialog",
                  {
                    attrs: { "max-width": "500" },
                    model: {
                      value: _vm.approvalDialogOpen,
                      callback: function($$v) {
                        _vm.approvalDialogOpen = $$v
                      },
                      expression: "approvalDialogOpen"
                    }
                  },
                  [
                    _c("ApprovalDialog", {
                      attrs: {
                        report: _vm.reportModel,
                        title: _vm.title,
                        status: _vm.status,
                        role: "preCheck",
                        approval: _vm.approval
                      },
                      model: {
                        value: _vm.approvalDialogOpen,
                        callback: function($$v) {
                          _vm.approvalDialogOpen = $$v
                        },
                        expression: "approvalDialogOpen"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }