import { Component, Watch } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { formatDate, sanitizeString } from '@/support/String';
import { parseWarning } from '@/support/Warning';
import { Report as ReportModel, Answer } from '@/models/Report';
import { Damage } from '@/models/DamageNew';
import { DamageVisibility } from '@/components/damage-panel/DamagePanel';
import { AlertBar } from '@/support/AlertBar';
import Storage from '@/support/Storage';
import { Lock } from '@/support/Lock';
import { debounce } from 'lodash';
import IdleWarningDialog from '@/components/IdleWarningDialog';
import { Options } from '@/components/mi-dialog/MiDialog';
import { GeneralKey, GeneralKeys } from '@/support/GeneralKeys';

@Component<PreCheck>({})
export default class PreCheck extends IdleWarningDialog {
  public $pageTitle = 'Voorcontrole';

  // LOCK
  protected lock: Lock = new Lock();

  protected lockKey = '';

  protected reportModel: ReportModel = new ReportModel();

  protected reportClone: ReportModel | null = null;

  protected damageModel: Damage = new Damage();

  protected damages: Damage[] = [];

  // approval bar
  protected approvalDialogOpen = false;

  protected approval = '';

  protected status = '';

  protected title = '';

  protected fileUrl = '';

  protected currentPdfType = '';

  protected isLoading = false;

  protected isAllowedToView = false;

  protected isAllowedToViewDialog = false;

  protected debounceExplanation:Function = debounce(this.updateExplanation, 1500);

  protected damageVisibility: DamageVisibility = {
    validations: true,
    manager: true,
    edit: true,
    rejectReasons: true,
    internal: true,
    reject: true,
    approve: true,
  };

  protected generalKeys: GeneralKey = {
    SchadeadresStraat: GeneralKeys.SchadeadresStraat,
    SchadeadresHuisnummer: GeneralKeys.SchadeadresHuisnummer,
    SchadeadresHuisnummerToevoeging: GeneralKeys.SchadeadresHuisnummerToevoeging,
    SchadeadresPlaats: GeneralKeys.SchadeadresPlaats,
    SchadeadresPostcode: GeneralKeys.SchadeadresPostcode,
    DatumAanvraag: GeneralKeys.DatumAanvraag,
    BouwJaar: GeneralKeys.BouwJaar,
    EigendomSinds: GeneralKeys.EigendomSinds,
    storyOfApplicant: GeneralKeys.storyOfApplicant,
    ExpertComment: GeneralKeys.ExpertComment,
    relevantRemarksApplicant: GeneralKeys.relevantRemarksApplicant,
  };

  protected created() {
    window.onbeforeunload = () => {
      this.lock.unlock(this.lockKey);
    };
  }

  public mounted() {
    // LOCK
    this.createLock();

    this.initialize();
  }

  protected async initialize() {
    this.isLoading = true;
    await this.getReport();
    await this.getDamages();
    this.getStorageValues();

    this.isLoading = false;
  }

  protected async createLock() {
    this.lockKey = `report:${this.$route.params.id}:editor:precheck`;
    await this.lock.checkLock(this.lockKey);
    await this.lock.initLock(this.lockKey);
    this.setAlert();
  }

  protected get isLockedByUser(): boolean {
    return this.lock.isLockedByUser;
  }

  protected setAlert() {
    if (this.lock.user) {
      this.$store.state.alert.message = AlertBar.setIsLockedMessage(this.lock.user);
      this.$store.state.alert.show = true;
    } else {
      this.$store.state.alert.show = false;
    }
  }

  protected getStorageValues() {
    if (Storage.get('preCheck_currentPdf')) {
      this.setCurrentPdf(`${Storage.get('preCheck_currentPdf')}`);
    }
  }

  protected destroyed() {
    this.lock.unlock(this.lockKey);
    Storage.delete('preCheck_currentPdf');
    Storage.delete('preCheck_panels');
  }

  protected handlePanelChanged(panels: boolean[]) {
    Storage.set('preCheck_panels', JSON.stringify(panels));
  }

  protected get panelsFromStorage() {
    return JSON.parse(`${Storage.get('preCheck_panels')}`);
  }

  protected async getReport() {
    await new ReportModel()
      .include(['applicant', 'answers', 'validations', 'reject_reasons', 'total_repairs'])
      .find(this.$route.params.id)
      .then((reportModel: ReportModel) => {
        this.reportModel = reportModel;

        if (! this.fileUrl) {
          this.fileUrl = reportModel.links.quick;
          this.currentPdfType = 'quick';
        }
        this.initBreadcrumb();
        this.setGeneralInfo();
        this.isLoading = false;

        this.isAllowedToViewDialog = true;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getDamages() {
    this.damages = await new Damage()
      .refactor()
      .include('validations')
      .filter({
        report: this.$route.params.id,
      })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async claimDossier() {
    await this.reportModel
      .update({
        pre_controller: this.$store.state.Auth.uuid,
      });

    this.$store.dispatch('openDialog', this.dialogOptionsConfirmClaim);
  }

  protected allowedToView(allowed: boolean) {
    this.isAllowedToView = allowed;
  }

  protected setCurrentPdf(type: string) {
    this.currentPdfType = type;
    Storage.set('preCheck_currentPdf', type);

    if (type === 'quick') {
      this.fileUrl = this.reportModel.links.quick;
    } else if (type === 'preview') {
      this.fileUrl = this.reportModel.links.preview;
    } else {
      this.fileUrl = '';
    }
  }

  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Alle rapporten', path: '/reports' },
          { name: this.reportModel.case_number, path: `/reports/${this.reportModel.uuid}` },
          { name: 'Voorcontrole' },
        ],
      });
  }

  protected parseWarning(alert: string) {
    return parseWarning(alert);
  }

  protected formatDateSeconds(date: string) {
    return formatDate(date, 'dd-LL-yyyy HH:mm');
  }

  protected setGeneralInfo() {
    const keys = Object.keys(this.generalKeys);
    if (! this.reportModel.answers) {
      return;
    }

    this.reportModel.answers.forEach((anwser: Answer) => {
      if (keys.includes(anwser.key ? anwser.key : '')) {
        (this.generalKeys as any)[anwser.key ? anwser.key : ''].value = anwser.value;
      }
    });
  }

  protected navigateToUrl(url: string) {
    return this.$router.push(url);
  }

  protected sanitizeString(value: string): string {
    return sanitizeString(value, true);
  }

  protected updateExplanation() {
    const payload = {
      internal_reject_comment: this.reportModel.internal_reject_comment,
    };

    this.reportModel
      .update(payload)
      .then(() => {})
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected toggleApproveDialog(status: string, title: string, approval: string) {
    this.status = status;
    this.approval = approval;
    this.title = title;
    this.approvalDialogOpen = true;
  }

  protected get dialogOptionsConfirmClaim(): Options {
    return {
      title: 'Gelukt!',
      text: 'U bent nu de voorcontroleur van dit dossier.',
      type: 'success',
      buttons: {
        confirm: {
          text: 'ok',
        },
      },
    };
  }

  @Watch('$route')
  public routeChanged(to: any, from: any) {
    this.initialize();
  }
}
